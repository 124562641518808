import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { retrieveToken } from './adapters/tokenManager';
import { ToastContainer } from 'react-toastify';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const AppLayout = React.lazy(() => import('./containers/AppLayout'));

// Pages
const Login = React.lazy(() => import('./pages/login/Login'));
const Register = React.lazy(() => import('./pages/register/Register'));
const ResetPassword = React.lazy(() => import('./pages/reset/ResetPassword'));
const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const Page500 = React.lazy(() => import('./pages/page500/Page500'));

class App extends Component {

  render() {
    return (
      <HashRouter basename="/">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/reset_password" name="Reset Password" render={props => <ResetPassword {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route path="/" name="Home" render={props => <AppLayout {...props} _token={retrieveToken()} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
