// TODO: store in react state instead of browser, use a provider class

const storeToken = (/** @type {string} */ uname, /** @type {string} */ token) => {
    localStorage.setItem(`dbtt_token`, token);
};

const retrieveToken = (/** @type {string} */ uname = '') => {

    return localStorage.getItem(`dbtt_token`) || '';
};

const clearToken = (/** @type {string} */ uname) => {
    localStorage.removeItem(`dbtt_token`);
}

export { storeToken, retrieveToken, clearToken };
